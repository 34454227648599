import { Module, VuexModule } from "vuex-module-decorators";
import InitCashRegisterState from "./models/InitPaymentState";
//con esto creamos el modulo dinamico
@Module({ namespaced: true, name: "CashRegister"})
export default class CashRegisterModule extends VuexModule implements InitCashRegisterState {
    /** INICIO - Inicializacion del estado */

    loading = false;
    active = true;
    /** FIN - Inicializacion del estado */

}