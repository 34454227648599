import { configure } from "vee-validate";
import { setLocale } from 'yup';
import { translate } from "./i18n";
import { getTypeNameLocation, ValueInputTypes } from "../enums/value-input-types";

export function initVeeValidate() {
  setLocale({//configuracion para sobreescribir el idioma
    mixed:{
      required: translate("VALIDATIONS.REQUIRED")
      , notType(params) {
        const type = params.type == 'number' ? ValueInputTypes.Number : ValueInputTypes.String
        const typeLocation = getTypeNameLocation(type);
        return translate("VALIDATIONS.NO_TYPE", {type: typeLocation})
      },
    },
    number: {
      positive: translate("VALIDATIONS.POSITIVE")
      , integer: translate("VALIDATIONS.INTEGER")     
    },
    string: {
      email: translate("VALIDATIONS.EMAIL")
    },
  })
  // Updating default vee-validate configuration
  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
  });
}
