import Swal from "@/core/plugins/sweetalert2";
import { SwalIconType } from "../enums/swal-icon-type";
import { translate } from "../plugins/i18n";
import MessageService from "./interfaces/MessageService";

/**
 * Servicio que muestra los mensaje al usuario en el sistema
 */
class SwalMessageService implements MessageService {

    error(message: string): void {
        Swal.fire({
            icon: 'error',
            title: message,
            showConfirmButton: false,
            timer: 5000   
        })
    }

    success(message: string) : void{
        Swal.fire({
            icon: 'success',
            title: message,
            showConfirmButton: false,
            timer: 3500   
        })
    }
    warning(message: string): void {
        Swal.fire({
            icon: SwalIconType.Warning
            , title: message
        })
    }

    async confirm(iconType: SwalIconType, message: string): Promise<boolean> {
        return Swal.fire({
            title: message,
            icon: iconType.toString(),
            showCancelButton: true,
            confirmButtonText: translate('MESSAGES.YES'),
            cancelButtonText: translate('MESSAGES.NO'),
        }).then(({ isConfirmed }) => isConfirmed);
    }

    /*async question(question: string): Promise<boolean> { 
        return Swal.fire({
            title: question
            , icon: 'question'
            , 
        })
    }*/
}

export default SwalMessageService;