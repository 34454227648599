import { RouteNames } from "@/router/route-names";
import AccessRouteAction from "./interfaces/AccessRouteAction";

export default abstract class AbstractAccessRoute implements AccessRouteAction {
    /**
     * Siguiente validacion de ruta
     */
    private nextAccessRoute?: AccessRouteAction = undefined;

    setNextAccessRoute(accessRoute: AccessRouteAction): AccessRouteAction {
        this.nextAccessRoute = accessRoute;
        return accessRoute;
    }
    canAccess(routeName: RouteNames): boolean {
        if (this.nextAccessRoute) {
            return this.nextAccessRoute.canAccess(routeName);
        }
        return true;
    }
    
}