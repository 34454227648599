/**
 * Nombres de las rutas disponibles
 */
export enum RouteNames {
    Dashboard = "dashboard"
    , Welcome = "welcome"
    , CashRegister = "cashregister"
    , SignIn = "sign-in"
    , SignUp = "sign-up"
    , PasswordReset = "password-reset"
    , ChangePasword = "change-password"
    , Sales = "sales"//admin sales
    , CloseCash = "CloseCash"
    , Deliveries = 'deliveriesViewTable'
    , Clients = "clients"
    , Bill = "bill"
    , BillCreated = "bill-created"
    , BillSale = "bill-sale"
    , SalesQuote = "salesQuote"
    , TypeCfdi = "types-use-cfdi"
    , WayToPay = "way-to-pay"
    , PaymentType = "payment-type"
    , Country = "country"
    , State = "state"
    , Population = "population"
    , PhysicalCount = "physical-count"
    , MovementPreview = "movement-preview"
    , Regime = "regime"
    , OpenCash = "OpenCash"
    , AdditionalFund = "AdditionalFund"
    , RetireCash = "RetireCash"
    , PurchaseOrders = "PurchaseOrdersAdmin"
    , Receptions = "ReceptionsAdmin"
    /**Modulos */
    , Users = "users"
    , SalesDetail = "/sales/detailSales"
    , SalesReturns = "salesReturns"
    , SalesModule = "sales-module"//admin sales
    , ShoppingModule = "shopping"//admin sales
    , BillingModule = "billing"//todos los modulos del catalogo
    , PhysicalInventoryModule = "physical-inventory"//admin sales
    , PhysicalCountDetailsModule = "physical-count-details"//conteo fisico
    /**CATALOGOS */
    , AllCatalogs = 'catalogs'
    , SubFamilies = "mod-subFamilies"
    , Families = "mod-families"
    , Cashiers = "cashiers"
    , CashRegisters = "cash-registers"//ya no se usa
    , CashMovements = "CashMovements"
    , CashMovementsDetails = 'CashMovementsDetails'
    , Transfers = 'transfers'//traspasos de almacen
    , TransfersDetails = 'transfers-details'//traspasos de almacen
    , MovementType = 'movement-type'
    , Outputs = '/warehouse/adminOutputs'
    , OutputsDetails =  '/warehouse/output/:outputId/details'
    , Inputs = '/warehouse/adminInputs'
    , InputsDetails = '/warehouse/input/:inputId/details'
    , WareHouse = 'WareHouse'
    , AdminSalesPay = '/adminSalesPay' 
}