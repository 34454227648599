import { SwalIconType } from "@/core/enums/swal-icon-type";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import { translate } from "@/core/plugins/i18n";
import { User } from "@/store/modules/auth/models/User";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import CredentialRequest from "@/models/auth/CredentialRequest"
import { AxiosResponse } from "axios";
import ChangePasswordRequest from "@/models/user/ChangePasswordRequest";
import RefreshTokenRequest from "@/models/auth/RefreshTokenRequest";

/**
 * Servicio de las acciones de la cuenta del usuario
 */
export default class AccountService implements GeneralService {
    apiController = "/Account";
    messageService: MessageService = new SwalMessageService();

    async logout(): Promise<boolean>{
        return this.messageService.confirm(SwalIconType.Warning, translate("MESSAGES.LOGOUT"))
            .then((confirmResult) => {
                if(confirmResult) {
                    return ApiService.post<boolean>(this.apiController + "/logout", {})
                    .then(() => {
                        return true;
                    })
                    .catch((error) => {
                        getMessageError(error, this.messageService);
                        return false;
                    });
                }
                return false;
            })
    }

    async login(credentials: CredentialRequest): Promise<AxiosResponse<User>> {
        return ApiService.post<User>(this.apiController + "/login", credentials)
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                getMessageError(error, this.messageService);
                return { data: null} as AxiosResponse;
            });
    }

    async changePassword(request: ChangePasswordRequest): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController + "/changepassword", request)
                .then((resp) => {
                    this.messageService.success(translate('MESSAGES.CHANGE_PASSWORD'));
                    
                    return { ...resp, data: true};
                })
                .catch((error) => {
                    getMessageError(error, this.messageService);
                    return {data: false} as AxiosResponse;
                });
    }
    async refreshToken(request: RefreshTokenRequest): Promise<AxiosResponse<RefreshTokenRequest>> {
        return ApiService.post<RefreshTokenRequest>(this.apiController + "/refreshToken", request)
                .then((resp) => {
                    return resp;
                })
                .catch((error) => {
                    getMessageError(error, this.messageService);
                    return {data: null} as AxiosResponse;
                });
    }
} 