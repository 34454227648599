
import { defineComponent, ref, toRefs, watch } from "vue";

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },

    screenPercentage: {
      type: Number,
      required: false,
      default: 50,
    },

    // Transition Speed in Milliseconds
    speed: {
      type: Number,
      required: false,
      default: 300,
    },

    backgroundColor: {
      type: String,
      required: false,
      default: "#fafafa",
    },
    direction: {
        type: String
        , default: "right"
    },
    closeInOverlay: {
      type: Boolean
      , default: false
    }
  },
  emits: [ "close"],
  setup(props, context) {
    //variables
    const { isOpen, speed, closeInOverlay } = toRefs(props);
    const isVisible = ref(false);
    const isTransitioning = ref(false);

    isVisible.value = isOpen.value;

    //methods
    const toggleBackgroundScrolling = (enable: boolean) => {
        const body = document.querySelector("body");
        if(body){
            body.style.overflow = enable ? "hidden" : "";
        }
    }
    const closeDrawer = (forceClose = false) => {
        if ((forceClose || closeInOverlay.value)&& !isTransitioning.value) {
            context.emit("close");
        }
    }

    //watchs
    watch(isOpen, (oldValue) => {
        isTransitioning.value = true;

        if (oldValue) {
            toggleBackgroundScrolling(true);
            isVisible.value = true;
        } else {
            toggleBackgroundScrolling(false);
            setTimeout(() => (isVisible.value = false), speed.value);
        }

        setTimeout(() => (isTransitioning.value = false), speed.value);
    })

    return {
      isVisible
      , isTransitioning
      //methods
      , closeDrawer
    };
  }
})
