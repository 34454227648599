import AbstractAccessRoute from "@/core/patters/AbstractAccessRoute";
import { RouteNames } from "@/router/route-names";
import store from "@/store";

/**
 * Valida si puedo aceder a la ruta del dashboard
 */
export default class CanAccessRouteDashboard extends AbstractAccessRoute {

    public canAccess(routeName: RouteNames): boolean {
        /**
         * Si la vista es la del panel entonces valida el permiso
         */
        if(routeName == RouteNames.Dashboard && !store.getters['showDasboardStadistic']){
            return false;   
        }
        //si no llama al siguiente acceso.
        return super.canAccess(routeName);
    }
}