import { translate } from "../plugins/i18n";
import MessageService from "../services/interfaces/MessageService";
import Error400Pulmeros from "../shared/models/error400Pulmeros";
import ErrorPulmeros from "../shared/models/ErrorPulmeros";

/**
 * Accion que envia un mesage al usuario basado en los datos de del sistema de pulmeros
 * @param headers headers de la respuesta con el mensaje que retorna
 * @param defaultMessageCode mensaje por defecto sii no se encuntra uno en el header
 * @param messageService servicio de mensajeria para poder dispararlo al usuario
 */
const getMessageHeader = (headers, defaultMessage: string, messageService: MessageService) => {
    try {
        const jsonMessage = headers["pulmeros-message"];
        const message = JSON.parse(jsonMessage).message;
        messageService.success(message);

    } catch (error) {
        //si falla se envia el mensaje generico
        messageService.success(defaultMessage);
    }
}

/**
 * 
 * @param error Error
 * @param messageService servicio que dispara el mensaje de error al ususario
 * @param propMessageError nombre de la propiedad que tendra contenido el mensaje de error
 * @param propCodeError nombre de la propiedad que tendra el codigo de error
 */
const getMessageError = (error, messageService: MessageService, propMessageError = 'Description', propCodeError = 'Code') => {
    if(error && error.response && error.response.data){
        try {
            let message = '';
            if(error.response.status >= 400 && error.response.status < 500){
                const errors: Error400Pulmeros[] = JSON.parse(error.response.data.message)
                errors.map((item) => {
                    message += (item[propCodeError] ? translate("MESSAGES.PULMEROS." + item[propCodeError]) : item[propMessageError]) + '<br>';
                });
            }else {
                const errors: ErrorPulmeros[] = JSON.parse(error.response.data.message)
                errors.map((item) => {
                    message += item.MessageError + '<br>';
                });
            }
            
            messageService.error(message);
        } catch (error) {
            messageService.error(translate("MESSAGES.GET_INFO_ERROR"));
        }
        
    } else {
        messageService.error(translate("MESSAGES.GET_INFO_ERROR"));
    }
}

export {getMessageHeader, getMessageError}