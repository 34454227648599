import { RouteNames } from './../../router/route-names';
import CanAccessRouteCatalogs from './accessRoutes/canAccessRouteCatalogs';
import CanAccessRouteDashboard from './accessRoutes/canAccessRouteDashboard';
import CanAccessRouteModules from './accessRoutes/canAccessRouteModules';
import CanAccessSystem from './accessRoutes/canAccessSystem';

/**
 * Indica si puede accesar a la ruta solicitada
 * @param pageName nombre la ruta a accesar
 * @returns 
 */
const canAccessRoute = (pageName: RouteNames) => {
    const canAccessSystem = new CanAccessSystem();
    const canAccessDashboard = new CanAccessRouteDashboard();
    const canAccessRouteCatalogs = new CanAccessRouteCatalogs();
    const canAccessRouteModules = new CanAccessRouteModules();
    /* DE ESTA MANERA PUEDES AGREGAR MAS ACCESOS A PAGINAS DIVERSAS*/
    canAccessSystem
        .setNextAccessRoute(canAccessDashboard)
        .setNextAccessRoute(canAccessRouteCatalogs)
        .setNextAccessRoute(canAccessRouteModules);
    
    return canAccessSystem.canAccess(pageName); //aqui podriamos agregar otras declaracion para
}

export { canAccessRoute }
