import HeadingPages from "@/core/config/interfaces/HeadingPages";
import AbstractAccessRoute from "@/core/patters/AbstractAccessRoute";
import { RouteNames } from "@/router/route-names";
import store from "@/store";

/**
 * Valida si puedo aceder a la ruta del modulo desde el menu configurable
 */
export default class CanAccessRouteModules extends AbstractAccessRoute {
    /**
     * Si la vista es la del panel entonces valida el permiso
     */
    public canAccess(routeName: RouteNames): boolean {
        const rootRoutesModules = (store.getters['getMenuConfig'] as HeadingPages[])
            .find(route => route.heading == "TITLES.MENU.MODULS")?.pages;
        const subRoutesModules = (store.getters['getMenuConfig'] as HeadingPages[])
            .find(route => route.heading == "TITLES.MENU.MODULS")?.pages?.flatMap(a => a.sub);
        
        if(rootRoutesModules && rootRoutesModules.map(pages => pages?.routeName)?.includes(routeName)){
            const route = rootRoutesModules.find(c => c?.routeName == routeName);
            return (route != undefined && route.canAccess);   
        } else if (subRoutesModules && subRoutesModules.map(pages => pages?.routeName)?.includes(routeName)) {
            //verificamos las subpaginas del modulo
            const route = subRoutesModules.find(c => c?.routeName == routeName);
            return (route != undefined && route.canAccess); 
        }
        //si no llama al siguiente acceso.
        return super.canAccess(routeName);
    }
}