import { RoleTypes } from "@/core/enums/role-types";
import { RelationRouteRoles } from "@/core/shared/models/RelationRouteRoles";
import { RouteNames } from "@/router/route-names";

/**
 * Rutas disponibles por medio de un role, 
 * NOTA: no es necesario, AGREGAR EL ROLE DEL ADMINSITRADOR... YA LO VALIDA EN OTRO ARCHIVO
 */
 const routesRoles: RelationRouteRoles[] = [
        {
            role: RoleTypes.AdminStore
            , availableRoutes: [
                RouteNames.SubFamilies
                , RouteNames.Families
                , RouteNames.Inputs
                , RouteNames.Outputs
                , RouteNames.WareHouse
                , RouteNames.Transfers
                , RouteNames.MovementType
                , RouteNames.Receptions
            ]
        },
        {
            role: RoleTypes.Seller
            , availableRoutes: [
                RouteNames.Clients
                , RouteNames.Sales
                , RouteNames.SalesDetail
                , RouteNames.SalesQuote
                , RouteNames.SalesReturns
                , RouteNames.SalesModule//si no tiene esta ruta no podemos ver ninguna de ventas
            ]
        },
        {
            role: RoleTypes.Cashier
            , availableRoutes: [
                RouteNames.RetireCash
                , RouteNames.AdditionalFund
                , RouteNames.OpenCash
                , RouteNames.CloseCash
                , RouteNames.Cashiers
                , RouteNames.CashRegisters//ya no se usa
                , RouteNames.CashRegister
                , RouteNames.AdminSalesPay
            ]
        },
        {
            role: RoleTypes.AdminCashRegister
            , availableRoutes: [
                //modulos de caja
                RouteNames.CashRegister
                , RouteNames.AdminSalesPay
                , RouteNames.OpenCash
                , RouteNames.AdditionalFund
                , RouteNames.RetireCash
                , RouteNames.CloseCash
                , RouteNames.CashMovements
                //modulo de compras
                , RouteNames.ShoppingModule
                , RouteNames.PurchaseOrders
                , RouteNames.Receptions
                //modulo de clentes
                , RouteNames.Clients

            ]
        },
        {
            role: RoleTypes.Storer
            , availableRoutes: [
                RouteNames.MovementType
            ]
        },
        {
            role: RoleTypes.AdminPurchasing
            , availableRoutes: [
                RouteNames.PurchaseOrders
            ]
        },
        {
            role: RoleTypes.Carrier
            , availableRoutes: [
                RouteNames.CashRegister
                , RouteNames.CloseCash
            ]
     },
     {
         role: RoleTypes.Logistics
         , availableRoutes: [
             RouteNames.SalesModule//si no tiene esta ruta no podemos ver ninguna de ventas
            , RouteNames.Deliveries
         ]
     }
];
    
export default routesRoles;