import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import Settlement from '@/store/modules/settlement';
import CashRegisterModule from "./modules/CashRegister";
import liquidationDetailModule from "./modules/liquidationDetail";
import PaymentsModule from "./modules/Payments";
import RolesModule from "./modules/roles/RolesModule";

config.rawError = true;

const store = createStore({
  modules: {
    RolesModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    //modulos propios
    Settlement,
    CashRegisterModule,
    PaymentsModule,
    liquidationDetailModule
  }
});

export default store;
