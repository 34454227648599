import { Module, VuexModule } from "vuex-module-decorators";
import SettlementState from "./models/SettlementState"
//con esto creamos el modulo dinamico
@Module({ namespaced: true, name: "Settlement"})
export default class SettlementModule extends VuexModule implements SettlementState {

    /** INICIO - Inicializacion del estado */

    loading = false;
    active = true;
    /** FIN - Inicializacion del estado */

}