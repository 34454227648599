import HeadingPages from "@/core/config/interfaces/HeadingPages";
import AbstractAccessRoute from "@/core/patters/AbstractAccessRoute";
import { RouteNames } from "@/router/route-names";
import store from "@/store";

/**
 * Valida si puedo aceder a la ruta del Catalogos desde el menu configurable
 */
export default class CanAccessRouteCatalogs extends AbstractAccessRoute {
    /**
     * Si la vista es la del panel entonces valida el permiso
     */
    public canAccess(routeName: RouteNames): boolean {
        const routesCatalog = (store.getters['getMenuConfig'] as HeadingPages[])
            .find(route => route.heading == "Settings")?.pages?.flatMap(a => a.sub);
        
        if(routesCatalog && routesCatalog.map(pages => pages?.routeName)?.includes(routeName)){
            const route = routesCatalog.find(c => c?.routeName == routeName);
            return (route != undefined && route.canAccess);   
        }
        //si no llama al siguiente acceso.
        return super.canAccess(routeName);
    }
}