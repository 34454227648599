import { encryptStorage } from "../plugins/encryptStorage";

const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return encryptStorage.getItem(ID_TOKEN_KEY) ?? "";
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  encryptStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  encryptStorage.removeItem(ID_TOKEN_KEY);
  encryptStorage.removeItem("user");
  encryptStorage.removeItem("roles");
};

export default { getToken, saveToken, destroyToken };
