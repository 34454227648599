/**
 * Cambia un numerico a una cadena con formato de moneda
 * @param quantity cantidad
 * @returns cadena con formato
 */
function toCurrency(quantity: number): string{
    if (isNaN(quantity)) {
        return "-";
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(quantity);
}

export default toCurrency;