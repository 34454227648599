import { translate } from "../plugins/i18n"

/**
 * Es el valor que puede tener los campos de tipo input (string, number)
 */
enum ValueInputTypes {
    String =  1
    , Number = 2
}


function getTypeNameLocation(value: ValueInputTypes): string {
    let valueTypeLocation = '';

    if (value == ValueInputTypes.Number) {
        valueTypeLocation = translate("ENUMS.VALUE_INPUT_TYPES.NUMBER")
    } else if (value == ValueInputTypes.String) { 
        valueTypeLocation = translate("ENUMS.VALUE_INPUT_TYPES.STRING")
    }
    return valueTypeLocation;
}
export { getTypeNameLocation , ValueInputTypes}