import store from '@/store';
import AbstractAccessRoute from "@/core/patters/AbstractAccessRoute";
import JwtService from "@/core/services/JwtService";
import { RouteNames } from "@/router/route-names";
import { Mutations } from '@/store/enums/StoreEnums';
import ErrorResponse from '@/models/general/ErrorResponse';

export default class CanAccessSystem  extends AbstractAccessRoute {
    
    public canAccess(routeName: RouteNames): boolean {
        /**
         * si son tutas que nonecesitan token las dejara ver.
         */
        if([RouteNames.SignIn, RouteNames.SignUp, RouteNames.PasswordReset].includes(routeName)){
            return true;
        }else if (JwtService.getToken()) {
            //aqui deberiamos validar el token por medio de un servicio
            //No existe el servicio mientras
            //si falla la validacion del token
            // entones debe enviar false
            // si no debe llamar al siguiente acceso para verifiar las demas paginas
            return super.canAccess(routeName);
         } else {
            store.commit(Mutations.SET_ERROR, [{
                code: "ERROR1"
                , description: "error con el token"
            }] as ErrorResponse[]);
            store.commit(Mutations.PURGE_AUTH);
            return false;
         }
        
    }
}