import { Module, VuexModule } from "vuex-module-decorators";
import InitLiquidationDetailState from "./models/InitLiquidationDetailState";

//con esto creamos el modulo dinamico
@Module({ namespaced: true, name: "CashRegister"})
export default class LiquidationDetailModule extends VuexModule implements InitLiquidationDetailState {
    /** INICIO - Inicializacion del estado */

    loading = false;
    active = true;
    /** FIN - Inicializacion del estado */

}