/**
 * Tipos de los roles permitidos
 */
 export enum RoleTypes {
    /**
     * Solicitante
     */
    Applicant = "Solicitante"
    , AdminCashRegister = "AdminCaja"
    , AdminServices = "AdminServicios"
    /**
     * Responsable de mantenimiento
     */
    , MaintenanceResponsible = "RespMantenimiento"
    , AdminShip = "AdminBarco"
    /**
     * Almacenista
     */
    , Storer = "Almacenista"
    /**
     * Administardor de almacen de producto
     */
    , AdminProductWarehouse = "AdminAlmacenProducto"
     , AdminReports = "AdmReportes"



     
     /**Roles de aluminios
      * Si arriba encuentras uno que te puiede servir pasalo aqui abajo para depues eliminar los demas
      */
    , Administrator = "Administrador"
    , Supplier = "Proveedor"
    , Cashier = "Cajero"
     /**
     * Administrador de compras
     */
    , AdminPurchasing = "AdminCompras"
     , PurchasingManager = "ResponsableCompras"
     /**
     * AdminAlmacen
     */
     , AdminStore = "AdminAlmacen"
     , Carrier = "Transportista"
   , Seller = "Vendedor"
   , Logistics = "Logistica"
   
}
